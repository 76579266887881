var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.showLoading
        ? _c(
            "div",
            { staticClass: "loading-wrapper" },
            [
              _c("van-loading", { attrs: { type: "spinner" } }, [
                _vm._v("支付中..."),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("van-image", {
        attrs: {
          width: "100%",
          height: "225",
          src: require("@/assets/package_header.jpg"),
        },
      }),
      _c("div", { staticClass: "goods-list__title" }, [_vm._v("我的订单")]),
      _c("div", { staticClass: "goods-list__tips" }, [
        _vm._v(
          " 先预约姓名相同最早的订单；我的预约信息要确认才能预约姓名相同的下次 "
        ),
      ]),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text":
                  _vm.list.length === 0 ? "暂无数据" : "没有更多了",
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.list, function (item) {
              return _c(
                "van-cell",
                { key: item.id, staticClass: "list-cell" },
                [
                  _c("div", { attrs: { slot: "default" }, slot: "default" }, [
                    _c(
                      "div",
                      { staticClass: "goods-container" },
                      [
                        _c("van-image", {
                          staticClass: "van-col imageBorder",
                          attrs: {
                            width: "80px",
                            height: "80px",
                            src: item.product.image,
                          },
                        }),
                        _c(
                          "van-row",
                          { staticClass: "content-box" },
                          [
                            _c("div", { staticClass: "content-name" }, [
                              _vm._v(_vm._s(item.product.store_name)),
                            ]),
                            _c("div", { staticClass: "content-des" }, [
                              _vm._v(
                                " 姓名: " +
                                  _vm._s(item.order_name) +
                                  " " +
                                  _vm._s(
                                    " 共" +
                                      item.total_appointment_num +
                                      "次剩" +
                                      item.appointment_num +
                                      "次"
                                  ) +
                                  " "
                              ),
                            ]),
                            item.paid === 1
                              ? _c("div", { staticClass: "content-des" }, [
                                  _vm._v(
                                    " 支付: " + _vm._s(item.pay_time) + " "
                                  ),
                                ])
                              : _vm._e(),
                            item.status === 3
                              ? _c(
                                  "van-tag",
                                  {
                                    staticClass: "bottom_right_icon",
                                    attrs: {
                                      round: "",
                                      type: "warning",
                                      size: "large",
                                      color: "gray",
                                    },
                                  },
                                  [_vm._v("已取消")]
                                )
                              : _vm._e(),
                            item.status !== 3 && item.paid === 1
                              ? _c(
                                  "van-tag",
                                  {
                                    staticClass: "top_right_icon",
                                    attrs: {
                                      round: "",
                                      type: "primary",
                                      size: "large",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goCourseRecord(item)
                                      },
                                    },
                                  },
                                  [_vm._v("疗程记录")]
                                )
                              : _vm._e(),
                            item.status !== 3 &&
                            item.paid === 1 &&
                            item.can_appointment &&
                            item.appointment_num > 0
                              ? _c(
                                  "van-tag",
                                  {
                                    staticClass: "bottom_right_icon",
                                    attrs: {
                                      round: "",
                                      type: "primary",
                                      size: "large",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goDetail(item)
                                      },
                                    },
                                  },
                                  [_vm._v("预约")]
                                )
                              : _vm._e(),
                            item.status !== 3 && item.paid === 0
                              ? _c(
                                  "van-tag",
                                  {
                                    staticClass: "bottom_right_icon",
                                    attrs: {
                                      round: "",
                                      type: "primary",
                                      size: "large",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goPayment(item)
                                      },
                                    },
                                  },
                                  [_vm._v("支付")]
                                )
                              : _vm._e(),
                            item.status !== 3 && item.paid === 0
                              ? _c(
                                  "van-tag",
                                  {
                                    staticClass: "bottom_right_icon2",
                                    attrs: {
                                      round: "",
                                      type: "warning",
                                      size: "large",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelOrder(item)
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "van-tabbar",
        {
          staticClass: "page-tottom-tabbar",
          on: { change: _vm.changeTabbar },
          model: {
            value: _vm.tabbarActive,
            callback: function ($$v) {
              _vm.tabbarActive = $$v
            },
            expression: "tabbarActive",
          },
        },
        [
          _c("van-tabbar-item", { attrs: { name: "home", icon: "home-o" } }, [
            _vm._v("首页"),
          ]),
          _c("van-tabbar-item", { attrs: { name: "case", icon: "star-o" } }, [
            _vm._v("数据"),
          ]),
          _c("van-tabbar-item", { attrs: { name: "package", icon: "bag-o" } }, [
            _vm._v("订单"),
          ]),
          _c(
            "van-tabbar-item",
            { attrs: { name: "personal", icon: "friends-o" } },
            [_vm._v("我的")]
          ),
        ],
        1
      ),
      _c(
        "van-dialog",
        {
          attrs: {
            title: "",
            "show-cancel-button": false,
            "show-confirm-button": false,
            "close-on-click-overlay": "",
          },
          model: {
            value: _vm.payDialogVisible,
            callback: function ($$v) {
              _vm.payDialogVisible = $$v
            },
            expression: "payDialogVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pay-container" },
            [
              _c("van-icon", {
                staticClass: "pay-dialong-icon-close",
                attrs: { name: "cross" },
                on: {
                  click: function ($event) {
                    _vm.payDialogVisible = false
                  },
                },
              }),
              _c("div", { staticClass: "pay-dialog-price" }, [
                _vm._v(
                  " ￥" + _vm._s(Number(_vm.orderInfo.product.price)) + " "
                ),
              ]),
              _c(
                "van-radio-group",
                {
                  model: {
                    value: _vm.payRadio,
                    callback: function ($$v) {
                      _vm.payRadio = $$v
                    },
                    expression: "payRadio",
                  },
                },
                [
                  _c("van-cell", {
                    attrs: { clickable: "" },
                    on: {
                      click: function ($event) {
                        _vm.payRadio = 1
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", {
                              staticClass: "iconfont icon-adl-weixin",
                            }),
                            _c("div", [_vm._v("微信支付")]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "right-icon",
                        fn: function () {
                          return [_c("van-radio", { attrs: { name: 1 } })]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pay-dialog-button-box" },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "pay-dialog-button",
                      attrs: { type: "info", round: "" },
                      on: { click: _vm.jumpPayment },
                    },
                    [_vm._v("去支付")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }